/* AdminPanel.css */

.admin-panel {
    display: flex;
    height: 100vh; /* Full viewport height */
  }
  
  .admin-content {
    margin-top: 30px;
    flex: 1; /* Flex-grow to fill remaining space */
  }
  
  @media (max-width: 768px) {
    .admin-panel {
      flex-direction: column; /* Stack sidebar on top of content on small screens */
    }
  }
  
  @media (min-width: 768px) {
    .admin-content {
      margin-left: 250px; /* Adjust based on sidebar width */
    }
  }
  