/* AddCar component styles */
  
  .form-container {
    max-width: 600px;
    margin: auto;
    background: #fff;
    padding: 20px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
  }
  
  .form-group {
    margin-bottom: 1.5rem;  
  }
  .form-group textarea {
    max-height: 100px; /* Adjust height as needed */
    overflow-y: auto;  /* Enable vertical scroll */
    resize: vertical;  /* Allow vertical resizing only */
  }
  .form-control {
    box-sizing: border-box; /* Ensure padding and borders do not exceed the width */
  }
    
  
  .form-check-label {
    margin-left: 0.5rem;
  }
  .container {
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }
  .text-muted {
    font-size: 0.875rem;
    color: #6c757d;
  }
  