/* styles.css */

body {
  background-color: #f8f9fa;
  padding-top: 56px;
}

.car-card {
  margin-bottom: 30px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.car-card:hover {
  transform: scale(1.05);
}


.navbar {
  z-index: 1100;
}

.sidebar {
  position: fixed;
  top: 56px;
  left: 0;
  height: calc(100vh - 56px);
  width: 250px !important;
  background-color: #343a40;
  color: white;
  z-index: 1000;
  overflow-y: auto;
  padding-top: 20px;
  transition: left 0.3s ease;
}

.sidebar ul {
  list-style-type: none;
  padding-left: 0;
}

.sidebar ul li {
  padding: 10px 15px;
  border-bottom: 1px solid #4e5d6c;
}

.sidebar ul li a {
  color: white;
  text-decoration: none;
}

.sidebar ul li a:hover {
  color: #007bff;
}

.content {
  margin-left: 250px; /* Adjust based on sidebar width */
  transition: margin-left 0.3s ease;
  padding-top: 20px;
}

@media (max-width: 1000px) {
  .sidebar {
    width: 100%;
    left: -100%;
  }

  .sidebar-open {
    left: 0;
  }

  .content {
    margin-left: 0;
    padding-top: 56px;
  }

  .sidebar ul li {
    border-bottom: none;
  }

  #sidebarToggle {
    position: fixed;
    top: 56px;
    left: 10px;
    z-index: 1050;
  }
}

.navbar-brand {
  font-family: 'Lobster', cursive;
  font-size: 1.8rem;
}

.no-cars-message {
  text-align: center;
  margin-top: 50px;
  font-size: 1.5rem;
  color: #6c757d;
}
 

.navbar-brand {
  font-family: 'Lobster', cursive;
  font-size: 1.8rem;
}

.header-section {
  background-image: url('https://images.unsplash.com/photo-1511919884226-dc6c19594a0f');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 50px 0;
  position: relative;
}

.header-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.header-section h1,
.header-section p {
  position: relative;
  z-index: 1;
}

.about-section {
  background: #f4f4f4;
  padding: 50px 0;
}

.map {
  width: 100%;
  height: 400px;
  border: 0;
  margin-top: 20px;
}

.card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.card:hover {
  transform: translateY(-10px);
  transition: transform 0.3s;
}

.card-title {
  color: #007bff;
}

.btn-custom {
  background: #007bff;
  color: white;
  border-radius: 20px;
  padding: 10px 20px;
}

.btn-custom:hover {
  background: #00c6ff;
  color: white;
}

.footer {
  background: #007bff;
  color: white;
  padding: 20px 0;
  text-align: center;
}

.scroll-link {
  color: white;
  transition: color 0.3s;
}

.scroll-link:hover {
  color: #00c6ff;
}
/* src/Components/styles.css */

.swiper-container {
  width: 100%;
  height: auto;
  padding: 20px 0;
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa; /* Light background for slides */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.swiper-slide:hover {
  transform: scale(1.05); /* Slight zoom on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.feature-grid {
  margin: 0 auto;
}

.feature-card {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh; /* Ensures each section takes up 50% of the viewport height */
  padding: 20px; /* Optional padding for spacing */
}

.feature-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.feature-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 15px; /* Add some padding between image and text */
}

.img-fluid {
  max-width: 100%;
  height: auto;
}


/* Responsive Design */
@media (max-width: 1024px) {
  .swiper-container {
    padding: 15px 0;
  }
}

@media (max-width: 768px) {
  .swiper-slide {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .swiper-slide {
    padding: 5px;
  }
}

/* src/Components/styles.css */
.car-carousel-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.car-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.car-details {
  padding: 10px;
}

.car-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.car-description {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.car-price {
  font-size: 1.25rem;
  font-weight: bold;
}
