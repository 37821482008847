/* AdminSidebar.css */

.admin-sidebar {
  width: 250px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #343a40;
  padding-top: 20px;
  z-index: 1000; /* Ensure AdminSidebar is above other content */
}

.admin-sidebar ul {
  list-style-type: none;
  padding: 0;
}

.admin-sidebar li {
  padding: 10px 20px;
}

.admin-sidebar li a {
  color: #fff;
  text-decoration: none;
  display: block;
}

@media (max-width: 768px) {
  .admin-sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }
}
