/* src/customStyles.css */
/* Custom styles */
body {
  background-color: #f8f9fa; /* Light gray background */
  font-family: Arial, sans-serif; /* Default font */
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Bebas Neue', sans-serif; /* Apply Bebas Neue font to headings */
}

.navbar {
  background-color: #ffffff; /* White navbar background */
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); /* Bottom border */
}

.navbar-brand {
  font-size: 1.8rem; /* Larger font size */
}

.navbar-nav .nav-link {
  font-size: 1.1rem; /* Larger font size for nav links */
  transition: 0.3s; /* Smooth transition */
}

.navbar-nav .nav-link:hover {
  color: #007bff; /* Hover color */
}

.dropdown-menu {
  border: none; /* Remove border from dropdown menu */
  background-color: #ffffff; /* White background */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow */
}

.dropdown-menu .dropdown-item {
  color: #333; /* Dark text color */
  transition: 0.3s; /* Smooth transition */
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f8f9fa; /* Light gray background on hover */
  color: #007bff; /* Hover color */
}

/* Adjusting the text color for the jumbotron */
.jumbotron {
  position: relative;
  background-image: url(./assets/CKZbg.png);
  background-size: cover; /* Corrected spelling of 'cover' */
  background-position: center;
  background-repeat: no-repeat;
  min-height: 70vh;
  color: #ffffff;
  padding: 100px 15px; /* Adjust padding for smaller devices */
  text-align: center;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .jumbotron {
    padding: 50px 15px;
    text-align: center;
  }
}

/* Apply dark overlay only to the background image */
.jumbotron::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Darker overlay with increased opacity */
  z-index: 1; /* Place overlay above the background image but below the text */
  pointer-events: none; /* Ensure overlay does not interfere with text or interactions */
}

/* Ensure text is above the overlay */
.jumbotron .content {
  position: relative; /* Ensure content is above the overlay */
  z-index: 2;
  color: #ffffff; /* Keep text color white */
}

.carousel-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px; /* Adjust as needed */
}

.carousel-card {
  width: 300px; /* Adjust as needed */
}
