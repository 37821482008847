.feature-list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 10px;
    margin-top: 10px;
  }
  
  .feature-item {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f8f8f8;
    margin-bottom: 10px;
    white-space: nowrap;
  }
  
  .modal-body {
    overflow-y: auto;
  }
  
  .modal-content {
    max-height: 90vh;
  }
  